import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
  return (
    <Box
      sx={{
        py: 2,
        px: 4,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // position: 'relative',
        // bottom: 0,
        // width: '100%'
      }}
    >
      <Typography variant="body2" color="textSecondary">
        Copyright &copy; 2024 Nikhil Agrawal. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
