import { Routes, Route, Navigate } from 'react-router-dom';
import About from './pages/about';
import Projects from './pages/projects';
import Contact from './pages/contact';
import PageNotFound from './pages/page-not-found';
import ResponsiveAppBar from './components/nav-bar';
import Footer from './components/footer';

function App() {
  return (
    <div>
      <ResponsiveAppBar />
      <Routes>
        <Route path='/' element={<Navigate to="about" replace />} />
        <Route path='/about' element={<About/>} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
