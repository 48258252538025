const primary = {
  main: '#1976D2',
  dark: '#0A2240',
  contrastText: '#fff',
  lighter: '#fff,'
};

const secondary ={ 
  main: '#168736', 
}

const error = {
  lightest: '#FADDDC',
  lighter: '#FF4136',
  light: '#E0362C',
  main: '#B9251C',
  contrastText: '#fff',
};

const success = {
  lightest: '#FBFEFC',
  lighter: '',
  light: '#57E188',
  main: '#168736',
  contrastText: '#fff',
};

const warning = {
  lightest: '#FF9831',
  lighter: '#FF800A',
  light: '#FF6D00',
  main: '#EB4F00',
  contrastText: '#2e2e38',
};

const info = {
  main: '#155CB4',
};

const breakpoints = {
  values: {
    xl: 1920,
    lg: 1280,
    md: 960,
    sm: 600,
    xs: 0,
  },
};

const background = {
  paper: primary.contrastText,
  default: primary.contrastText,
};

const htmlFontSize = 16;
const fontSize = 14;
const coef = fontSize / 14;
const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`;
const spacing = (factor: number) => `${0.5 * factor}rem`;


const typography = {
  htmlFontSize,
  fontSize,
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  button: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  // h1: {
  //   fontSize: `${pxToRem(36)}`,
  //   fontWeight: 300,
  //   lineHeight: `${pxToRem(46)}`,
  // },
  // h2: {
  //   fontSize: `${pxToRem(34)}`,
  //   fontWeight: 300,
  //   lineHeight: `${pxToRem(44)}`,
  // },
  // h3: {
  //   fontSize: `${pxToRem(32)}`,
  //   fontWeight: 300,
  //   lineHeight: `${pxToRem(40)}`,
  // },
  // h4: {
  //   fontSize: `${pxToRem(24)}`,
  //   fontWeight: 300,
  //   lineHeight: `${pxToRem(30)}`,
  // },
  // h5: {
  //   fontSize: `${pxToRem(20)}`,
  //   fontWeight: 300,
  //   lineHeight: `${pxToRem(28)}`,
  // },
};

export default {
  palette: {
    primary,
    secondary,
    error,
    success,
    warning,
    info,
    background,
    action: {
      disabled: primary.lighter,
    },
  },
  breakpoints,
  typography,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0 !important"
        },
      },
    },
  }
}