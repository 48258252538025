import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Link from "@mui/material/Link";
import GitHub from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const pages = ['About', 'Projects', 'Contact'];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuClick = (page: any) => {
    navigate(page);
  }

  const SmallScreenNavBar = () => {
    return (
      <>
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Nikhil Agrawal
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="Navigation"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link underline="none" component={RouterLink} to={`/${page}`}>
                    {page}
                  </Link>
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </>
    );
  };
  
  const BigScreenNavBar = () => {
    return (
      <>
        <Box sx={{ flexGrow: 1, justifyContent: 'flex-start', px: '40px', display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => (
            <Button
              key={page}
              onClick={() => handleMenuClick(`/${page}`)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page}
            </Button>
          ))}
        </Box>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
            justifyContent: 'center'
          }}
        >
          Nikhil Agrawal
        </Typography>
        <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', px: '40px', display: { xs: 'none', md: 'flex' } }}>
          <IconButton sx={{ ml: 1 }} href='https://www.github.com/nagrawal3' target='_blank'>
            <GitHub />
          </IconButton>
          <IconButton sx={{ ml: 1 }} href='https://www.linkedin.com/in/nagrawal3' target='_blank'>
            <LinkedInIcon />
          </IconButton>
          <IconButton sx={{ ml: 1 }} href='https://twitter.com/nikhilagrawal91' target='_blank'>
            <XIcon />
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Large Screen Size */}
          <BigScreenNavBar />
          {/* Small Screen Size */}
          <SmallScreenNavBar />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
