import React from 'react';
import { Container, Box } from '@mui/material';

const Projects = () => {
  return (
    <Box
      component='main'
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh'
      }}
    >
      <Container maxWidth='sm'>
        <Box sx={{ my: 3 }}>
          <div>
            <iframe src="/Nikhil Agrawal.pdf" width="100%" height="500px" />
          </div>
        </Box>
      </Container>
    </Box>
  );
}

export default Projects;
